import Box from "@mui/material/Box";
import { ContactRoom, Employee, NewGraduateToDisplay } from "@onn/common";
import React, { FC, useState, useEffect, useCallback, useMemo } from "react";

import { TableRowProps } from "react-virtualized";

import { NewGraduateCheckbox } from "./TableRowItems/NewGraduateCheckbox";
import { NewGraduateContactRoom } from "./TableRowItems/NewGraduateContactRoom";
import { NewGraduateManageMenu } from "./TableRowItems/NewGraduateManageMenu";
import { NewGraduateMemo } from "./TableRowItems/NewGraduateMemo";
import { NewGraduateMentor } from "./TableRowItems/NewGraduateMentor";
import { NewGraduateName } from "./TableRowItems/NewGraduateName";

import { NewGraduateRecruitmentStatusAndPrediction } from "./TableRowItems/NewGraduateRecruitmentStatusAndPrediction";
import { TableRowWrapper } from "./TableRowWrapper";

import { useEmployeeIdToEmployeeMap } from "./hooks/useEmployeeIdToEmployeeMap";
import { useTableHeaders } from "./hooks/useTableHeaders";

import { VirtualizedInfiniteScrollTable } from "~/components/uiParts/VirtualizedInfiniteScrollTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { useAccessControl } from "~/hooks/accessControl";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useCurrentUser, useEmployeeModals, useNewGraduateModals } from "~/hooks/employee";

import { SearchNewGraduatesPerPageData } from "~/hooks/employee/useSearchNewGraduatesPerPage";
import { useModal } from "~/hooks/modal";
import { useQuery } from "~/hooks/shared";
import { MutateSearchNewGraduatesPerPageFunctions } from "~/pages/top/NewGraduate/useNewGraduateTable";

type Props = {
  searchNewGraduatesPerPageData: NonNullable<SearchNewGraduatesPerPageData>;
  selectedNewGraduateIds: Set<string>;
  checked: boolean;
  indeterminate: boolean;
  onSelectNewGraduateId(newGraduateId: string): void;
  onChangeCheckBox(): void;
  onLoadMore: () => Promise<void> | void;
} & MutateSearchNewGraduatesPerPageFunctions;

const widthOptions = ["7%", "35%", "32%", "8%", "17%"];
// TODO: 「次の予定」が入る時は以下の設定を使う
// const widthOptions = ["7%", "26%", "25%", "17%", "8%", "17%"];

const useContactRoomEtcMap = () => {
  const { contactRoomUnreadCountMap, contactRoomsWithoutCurrentUser: contactRooms } =
    useContactContext();

  const employeeIdToContactRoomMap = useMemo(() => {
    return new Map<string, ContactRoom>(contactRooms.map((room) => [room.employeeId || "", room]));
  }, [contactRooms]);

  return { employeeIdToContactRoomMap, contactRoomUnreadCountMap };
};

export const NewGraduateTable: FC<Props> = ({
  searchNewGraduatesPerPageData,
  selectedNewGraduateIds,
  onSelectNewGraduateId,
  onChangeCheckBox,
  onLoadMore,
  checked,
  indeterminate,
  mutateAllPagesOfSearchNewGraduatesPerPage: {
    mutate: mutateAllPagesOfSearchNewGraduatesPerPage,
    mutateWithOptimisticUpdate: mutateAllPagesOfSearchNewGraduatesPerPageWithOptimisticUpdate,
  },
}) => {
  const { currentUser } = useCurrentUser();
  const { isEditable } = useAccessControl();
  const { query } = useQuery();

  const rows = useMemo(
    () => searchNewGraduatesPerPageData.flatMap((data) => data.newGraduates),
    [searchNewGraduatesPerPageData]
  );

  const { employeeIdToEmployeeMap } = useEmployeeIdToEmployeeMap();

  const { employeeIdToContactRoomMap, contactRoomUnreadCountMap } = useContactRoomEtcMap();

  const { handleOpenDeleteEmployeeModal, handleAddTag } = useEmployeeModals({
    onDeleteEmployee: () => {
      mutateAllPagesOfSearchNewGraduatesPerPage();
    },
    onDismissSupportMemberConfirm: () => {
      mutateAllPagesOfSearchNewGraduatesPerPage();
    },
    onAssignTags: () => {
      mutateAllPagesOfSearchNewGraduatesPerPage();
    },
  });
  const { handleOpenAssignNewGraduateMentorModal, handleOpenManageMentorAndFollowersModal } =
    useNewGraduateModals({
      onUpdateNewGraduate: () => {
        mutateAllPagesOfSearchNewGraduatesPerPage();
      },
    });

  // 新卒向けと中途向けが混在しているのでuseEmployeeModalsを使わない
  const { handleModal } = useModal();

  const [isCheckQuery, setIsCheckQuery] = useState(false);

  const headers = useTableHeaders({ checked, indeterminate, onChangeCheckBox });

  // 担当者が設定されている場合管理モーダルを表示し、そうでない場合はAdminの場合にのみ設定モーダルを表示する
  const switchMentorModalOpen = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) => {
      if (mentor) {
        return handleOpenManageMentorAndFollowersModal(newHire, mentor, supportMembers);
      }
      if (isEditable(newHire)) {
        return handleOpenAssignNewGraduateMentorModal(newHire, mentor);
      }
    },
    [isEditable, handleOpenManageMentorAndFollowersModal, handleOpenAssignNewGraduateMentorModal]
  );

  const rowRenderer = useCallback(
    ({
      key,
      index,
      style,
      rowData: displayNewHire,
    }: Omit<TableRowProps, "rowData"> & {
      rowData: NewGraduateToDisplay;
    }) => {
      const mentor = employeeIdToEmployeeMap.get(displayNewHire.mentorUserId || "");
      const supportMembers = (displayNewHire.supportMemberEmployeeIds || []).flatMap((id) => {
        return employeeIdToEmployeeMap.get(id) || [];
      });
      const targetContactRoom = employeeIdToContactRoomMap.get(displayNewHire.id);

      const contents = [
        <NewGraduateCheckbox
          key={`checkbox-${displayNewHire.id}`}
          displayNewHire={displayNewHire}
          onSelectNewGraduateId={onSelectNewGraduateId}
          selectedNewGraduateIds={selectedNewGraduateIds}
        />,
        <NewGraduateName
          key={`name-${displayNewHire.id}`}
          newGraduate={displayNewHire}
          onUpdateRecruitmentStatus={({ updatedNewGraduate, updateRecruitmentStatusPromise }) => {
            // 変更した候補者を含む検索結果全体の楽観的更新とミューテーション
            mutateAllPagesOfSearchNewGraduatesPerPageWithOptimisticUpdate(
              updatedNewGraduate,
              updateRecruitmentStatusPromise
            );
          }}
        />,
        <NewGraduateRecruitmentStatusAndPrediction
          key={`scenario-${displayNewHire.id}`}
          newGraduate={displayNewHire}
          onUpdateRecruitmentStatus={({ updatedNewGraduate, updateRecruitmentStatusPromise }) => {
            // 変更した候補者を含む検索結果全体の楽観的更新とミューテーション
            mutateAllPagesOfSearchNewGraduatesPerPageWithOptimisticUpdate(
              updatedNewGraduate,
              updateRecruitmentStatusPromise
            );
          }}
          onEditEmployeePredictionRelation={({
            updatedNewGraduate,
            editEmployeePredictionRelationsPromise,
          }) => {
            // 変更した候補者を含む検索結果全体の楽観的更新とミューテーション
            mutateAllPagesOfSearchNewGraduatesPerPageWithOptimisticUpdate(
              updatedNewGraduate,
              editEmployeePredictionRelationsPromise
            );
          }}
        />,
        <NewGraduateMentor
          key={`mentor-${displayNewHire.id}`}
          mentor={mentor}
          isEditable={isEditable(displayNewHire)}
          onClickMentor={() => switchMentorModalOpen(displayNewHire, mentor, supportMembers)}
        />,
        <Box key={`icons-${displayNewHire.id}`} display="flex" justifyContent="space-evenly">
          <NewGraduateMemo
            newGraduate={displayNewHire}
            onUpdateMemo={mutateAllPagesOfSearchNewGraduatesPerPage}
          />
          <NewGraduateContactRoom
            // 未招待の場合はコンタクトルームへの動線を表示しない
            disabledContactRoomIcon={
              displayNewHire.isNotRegisteredAndInvited() || !targetContactRoom?.id
            }
            contactRoomId={targetContactRoom?.id}
            unreadContactMessageCount={contactRoomUnreadCountMap.get(targetContactRoom?.id || "")}
          />
          {isEditable(displayNewHire) && (
            <NewGraduateManageMenu
              newHire={displayNewHire}
              onClickDeleteAccountButton={() => handleOpenDeleteEmployeeModal(displayNewHire)}
              onClickInvitationButton={() =>
                handleModal({
                  name: "inviteNewGraduateModal",
                  args: {
                    currentUser,
                    newGraduate: displayNewHire,
                    onSendInvitationNotification: () => {
                      mutateAllPagesOfSearchNewGraduatesPerPage();
                    },
                    onUpdateRecruitmentStatus: () => {
                      mutateAllPagesOfSearchNewGraduatesPerPage();
                    },
                  },
                })
              }
              onClickSwitchMentorButton={() =>
                switchMentorModalOpen(displayNewHire, mentor, supportMembers)
              }
              onClickAddTag={() => {
                handleAddTag(displayNewHire);
              }}
            />
          )}
        </Box>,
      ];

      return (
        <TableRowWrapper key={key} index={index} {...style}>
          <TableRow
            row={{
              contents,
              to: `/employee/${displayNewHire.id}`,
              isTargetBlank: true,
            }}
            widthOptions={widthOptions}
            hover={true}
          />
        </TableRowWrapper>
      );
    },
    [
      employeeIdToEmployeeMap,
      employeeIdToContactRoomMap,
      onSelectNewGraduateId,
      selectedNewGraduateIds,
      isEditable,
      contactRoomUnreadCountMap,
      switchMentorModalOpen,
      mutateAllPagesOfSearchNewGraduatesPerPage,
      mutateAllPagesOfSearchNewGraduatesPerPageWithOptimisticUpdate,
      handleOpenDeleteEmployeeModal,
      handleModal,
      currentUser,
      handleAddTag,
    ]
  );

  useEffect(() => {
    if (isCheckQuery) return;
    setIsCheckQuery(true);

    const targetEmployeeId = query.get("targetEmployeeId");

    const newHire = employeeIdToEmployeeMap.get(targetEmployeeId || "");
    if (!newHire) return;

    const mentor = employeeIdToEmployeeMap.get(newHire.mentorUserId || "");

    // クエリパラメータで指定されている場合はダイアログを開く
    switch (query.get("openDialogType")) {
      case "mentor":
        handleOpenAssignNewGraduateMentorModal(newHire, mentor);
        break;
    }
  }, [query, isCheckQuery, employeeIdToEmployeeMap, handleOpenAssignNewGraduateMentorModal]);

  const handleLoadMoreRows = useCallback(async () => {
    const nextCursor =
      searchNewGraduatesPerPageData[searchNewGraduatesPerPageData.length - 1]?.nextCursor;
    const canLoadMore = !!nextCursor;

    if (canLoadMore) await onLoadMore();
  }, [onLoadMore, searchNewGraduatesPerPageData]);

  const hasNextPage = searchNewGraduatesPerPageData
    ? !!searchNewGraduatesPerPageData[searchNewGraduatesPerPageData.length - 1]?.nextCursor
    : true;

  return (
    <VirtualizedInfiniteScrollTable<NewGraduateToDisplay>
      headers={headers}
      widthOptions={widthOptions}
      rows={rows}
      hasNextPage={hasNextPage}
      headerHeight={40}
      rowHeight={106}
      rowRenderer={rowRenderer}
      onLoadMore={handleLoadMoreRows}
    />
  );
};
