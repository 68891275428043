import { Box } from "@material-ui/core";
import { getHowLongAgo, NewGraduateToDisplay } from "@onn/common";
import React, { ComponentProps, FC, memo, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateStatusChip } from "../NewGraduateStatusChip";

import { NewGraduateInvitationChip } from "../NewGraduateStatusChip/NewGraduateInvitationChip";

import { Icon } from "~/components/uiParts";
import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

type Props = {
  newGraduate: NewGraduateToDisplay;
  isPinned?: boolean;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof NewGraduateStatusChip
  >["onUpdateRecruitmentStatus"];
  statusVisibilityType?: "onlyInvitationStatus" | "recruitmentStatusAndInvitationStatus";
};

// NOTE: statusVisibilityTypeは変な条件分岐になっているが、複数シナリオ実装時に修正されるので妥協
export const NewGraduateUseIcon: FC<Props> = memo(
  ({
    newGraduate,
    isPinned,
    onUpdateRecruitmentStatus,
    statusVisibilityType = "recruitmentStatusAndInvitationStatus",
  }) => {
    const employeeStatusText = useMemo(() => {
      return newGraduate.lastActiveTime ? getHowLongAgo(newGraduate.lastActiveTime) : "なし";
    }, [newGraduate.lastActiveTime]);

    const iconBadgeType: "email" | "lineChat" | undefined = useMemo(() => {
      if (!newGraduate.uid && !newGraduate.lineUserId) return undefined;
      // NOTE: undefinedは "登録前" かつLineUser未連携を表す

      switch (newGraduate.selectedAuthenticationFlowType) {
        case "line":
          return "lineChat";
        case "email":
          return "email";
      }
    }, [newGraduate]);

    const username = newGraduate.getName();

    return (
      <>
        <Box display="flex" alignItems="center" gridGap="16px">
          <UserIcon
            username={username}
            profileIconImageUrl={newGraduate.profileIconImageUrl || ""}
            size="small"
            backgroundColor={newGraduate.uid ? "primary" : "grey"}
            badgeType={iconBadgeType}
          />
          <Box display="grid" gridRowGap="4px">
            <Box display="flex" alignItems="center" gridGap="8px" overflow="hidden">
              {isPinned && <Icon icon="pin" color="primary" size="sm" />}
              <StyledTypography variant="body1" color="textPrimary" bold noWrap>
                {username}
              </StyledTypography>
              {statusVisibilityType === "onlyInvitationStatus" && !newGraduate.isRegistered() && (
                <NewGraduateInvitationChipWrapper>
                  <NewGraduateInvitationChip invited={newGraduate.isInvited()} />
                </NewGraduateInvitationChipWrapper>
              )}
            </Box>
            <Box display="flex" alignItems="center" gridGap="8px" flexWrap="wrap">
              <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
                最終アクセス：{employeeStatusText}
              </StyledTypography>
            </Box>
            {statusVisibilityType === "recruitmentStatusAndInvitationStatus" && (
              <NewGraduateStatusChip
                newGraduate={newGraduate}
                onUpdateRecruitmentStatus={onUpdateRecruitmentStatus}
              />
            )}
          </Box>
        </Box>
      </>
    );
  }
);

const NewGraduateInvitationChipWrapper = styled(Box)`
  .MuiChip-root {
    padding: 0;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;
