import { Box, Tooltip } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { ComponentProps, FC } from "react";

import { NewGraduateStatusChip } from "../../NewGraduateStatusChip";
import { NewGraduateSummaryPaper } from "../../NewGraduateSummaryPaper";

import { NewGraduateUseIcon } from "../../NewGraduateUseIcon";

type Props = {
  newGraduate: NewGraduateToDisplay;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof NewGraduateStatusChip
  >["onUpdateRecruitmentStatus"];
};

export const NewGraduateName: FC<Props> = ({ newGraduate, onUpdateRecruitmentStatus }) => {
  return (
    <Tooltip
      title={<NewGraduateSummaryPaper newGraduate={newGraduate} />}
      placement="bottom-start"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-10, 0],
              },
            },
          ],
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
          },
        },
      }}
    >
      <Box>
        {/* Boxがないと、ツールチップが表示されない */}
        <NewGraduateUseIcon
          newGraduate={newGraduate}
          onUpdateRecruitmentStatus={onUpdateRecruitmentStatus}
          statusVisibilityType={"onlyInvitationStatus"}
        />
      </Box>
    </Tooltip>
  );
};
